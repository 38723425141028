import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';

const NotFoundPage = () => (
  <StaticQuery
    query={graphql`query Dexel404 {
  site {
    siteMetadata {
      title
    }
  }
  wordpressWpGmBrands(slug: {eq: "dexel"}) {
    slug
    content
    featured_media {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    information {
      primary_color
      background_color
      logo {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`}
    render={data => (
      <Layout
        siteTitle={data.site.siteMetadata.title}
        logo={data.wordpressWpGmBrands.information.logo}
        accentColor={data.wordpressWpGmBrands.information.primary_color}
        backgroundColor={data.wordpressWpGmBrands.information.background_color}
      >
        <Section id="not_found" className="content full-height">
          <SEO
            title="404: No encontrada"
            thumbnail={`${data.site.siteMetadata.publicURL}${data.wordpressWpGmBrands.information.logo.localFile.publicURL}`}
          />
          <center>
            <h1>404</h1>
            <p>Lo sentimos, esta página no existe.</p>
          </center>
        </Section>
      </Layout>
    )}
  />
);

export default NotFoundPage;
